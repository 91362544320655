import { navLinksArray } from "data/variables";
import Link from "next/link";
import styled from "styled-components";

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  border: 1px solid var(--background-color);
  overflow-y: auto;
  background-color: var(--raised-background-color);
  opacity: ${(props) => (props.active ? 1 : 0)};
  transform: ${(props) => (props.active ? "translate3d(-1rem, 1rem, 0)" : 0)};
  pointer-events: ${(props) => (props.active ? "auto" : "none")};
  border-radius: 2rem;
  display: none;
  flex-direction: column;
  font-size: 1.5rem;
  line-height: 2.8rem;
  transition: 0.2s linear;
  padding: 2rem;
  z-index: 2;
  a {
    color: var(--text-color);
    text-decoration: none;
  }
  @media screen and (max-width: 1140px) {
    display: flex;
  }
`;

const MobileMenuPanel = ({ menuDisplay, toggle }) => {
  return (
    <StyledWrapper active={menuDisplay}>
      <Link href={"/"} onClick={() => toggle(false)}>
        Home
      </Link>
      <Link href="/download" onClick={() => toggle(false)}>
        Download
      </Link>
      {navLinksArray.map((value) => {
        return value.label === "Support" ? (
          <a key={value.label} href="https://redact.dev/support/">
            {value.label}
          </a>
        ) : (
          <Link href={value.link} key={value.label} onClick={() => toggle(false)}>
            {value.label}
          </Link>
        );
      })}
      <Link href="/affiliates" onClick={() => toggle(false)}>
        Affiliates
      </Link>
      <Link href="/press-kit" onClick={() => toggle(false)}>
        Press Kit
      </Link>
      <Link href="/terms" onClick={() => toggle(false)}>
        Terms & Conditions
      </Link>
      <Link href="/privacy" onClick={() => toggle(false)}>
        Privacy
      </Link>
    </StyledWrapper>
  );
};

export default MobileMenuPanel;
